<template>
    <div class="flex flex-wrap">
      <div class="w-full lg:w-12/12 px-4">
        <NewEditMember />
      </div>
    </div>
  </template>
  <script>
  import NewEditMember from "@/components/Cards/NewEditMember.vue";
  
  export default {
    components: {
        NewEditMember,
    },
  };
  </script>