<template>
    <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
      :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
    >
      <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center">
          <div class="relative w-full px-4 max-w-full flex flex-1">
            <h3
              class="font-semibold text-lg"
              :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
            >
              {{ routeTitle }}
            </h3>
            <button
              class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 ml-auto"
              type="button"
              @click="newBlog"
            >{{ buttonTitle }}</button>
          </div>
        </div>
      </div>
      <div class="block w-full overflow-x-auto">
        <!-- Projects table -->
        <table class="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Naziv
              </th>
              <th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Aktivna
              </th>
							<th
                class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                :class="[
                  color === 'light'
                    ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                    : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                ]"
              >
                Opcije
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(blog, index) in blogs" :key="index">
              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center cursor-pointer"
                @click="openBlog(blog.blogId)"
              >
                <img
                  v-if="blog.imageUrl"
                  :src="blog.imageUrl"
                  class="h-12 bg-white rounded-4 border"
                  alt="..."
                />
                <span
                  class="ml-3 font-bold underline"
                  :class="[
                    color === 'light' ? 'text-blueGray-600' : 'text-white',
                  ]"
                >
                  {{ blog.title }}
                </span>
              </th>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <i v-if="!blog.visible" class="fas fa-circle text-orange-500 mr-2"></i>
                <i v-if="blog.visible" class="fas fa-circle text-emerald-500 mr-2"></i>
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
								<button
									class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
									type="button"
									@click.stop="removeBlog(blog.blogId)">
									Izbriši
								</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  <script>
  
  export default {
    data() {
      return {
      };
    },
    components: {
    },
    props: {
      color: {
        default: "light",
        validator: function (value) {
          // The value must match one of these strings
          return ["light", "dark"].indexOf(value) !== -1;
        },
      },
    },

    computed: {
      blogs() {
        return this.$store.getters.blogs || [];
      },

      routeTitle() {
        switch (this.$route.name) {
          case 'oglasna':
            return 'Oglasna ploča';
          case 'dokumenti':
            return 'Dokumenti';
          case 'radovi-projekti':
            return 'Radovi i projekti';
          default:
            return '';
        }
      },

      buttonTitle() {
        switch (this.$route.name) {
          case 'oglasna':
            return 'Novi oglas';
          case 'dokumenti':
            return 'Novi dokument';
          case 'radovi-projekti':
            return 'Novi rad/projekat';
          default:
            return 'Novi';
        }
      },
    },

    watch: {
      $route() {
        this.$store.dispatch('getBlogs', this.$route.name);
      }
    },

		mounted() {
			this.$store.dispatch('getBlogs', this.$route.name);
		},

    methods: {
      openBlog(id) {
        this.$store.dispatch('getBlogById', id).then(() => {
          this.$router.push({ name: 'blog', params: { id, tag: this.$route.name } });
        });
      },

			removeBlog(blogId) {
				this.$store.dispatch('deleteBlog', blogId).then(() => {
					this.$store.dispatch('getBlogs', this.$route.name);
				});
			},

      newBlog() {
        this.$router.push(
          { 
            name: 'blog',
            params: {
              id: 'new',
              tag: this.$route.name,
            },
          },
        );
      },
    }
  };
  </script>
  