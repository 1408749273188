import { createWebHistory, createRouter } from "vue-router";
// import store from './store';

// layouts

import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";

// views for Admin layout
import Blogs from "@/views/admin/Blogs.vue";
import Blog from "@/views/admin/Blog.vue";
import Gallery from "@/views/admin/Gallery.vue";
import Dashboard from "@/views/admin/Dashboard.vue";
import Members from "@/views/admin/Members.vue";

// views for Auth layout

import Login from "@/views/auth/Login.vue";
import Member from "@/views/admin/Member.vue";


// routes

const routes = [
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    component: Admin,
    meta: { requiresAuth: true },
    children: [
      {
        name: "oglasna",
        path: "/admin/oglasna-ploca",
        component: Blogs,
        meta: { requiresAuth: true }
      },
      {
        name: "dokumenti",
        path: "/admin/dokumenti",
        component: Blogs,
        meta: { requiresAuth: true }
      },
      {
        name: "radovi-projekti",
        path: "/admin/radovi-projekti",
        component: Blogs,
        meta: { requiresAuth: true }
      },
      {
        name: "blog",
        path: "/admin/section/:id/:tag",
        component: Blog,
        meta: { requiresAuth: true }
      },
      {
        name: "gallery",
        path: "/admin/gallery",
        component: Gallery,
        meta: { requiresAuth: true }
      },
      {
        name: "clanovi",
        path: "/admin/clanovi",
        component: Members,
        meta: { requiresAuth: true }
      },

      {
        name: "member",
        path: "/admin/clan/:id",
        component: Member,
        meta: { requiresAuth: true }
      },
    ],
  },
  {
    name: 'home',
    path: '/',
    redirect: '/admin/oglasna-ploca',
    component: Dashboard,
  },
  {
    path: "/login",
    redirect: "/auth/login",
    component: Auth,
    children: [
      {
        path: "/auth/login",
        name: "SignIn",
        component: Login,
      },
    ],
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
    // Check if the route requires authentication
    if (to.meta.requiresAuth) {
      const isAuthenticated = localStorage.getItem('user') !== null;
      if (!isAuthenticated) {
        // If user is not authenticated, redirect to login page
        next('/login');
        return;
      }
    }
    // Continue with navigation
    next();
  });

export default router