<template>
  <!-- Header -->
  <div class="relative bg-header-custom pb-32 pt-10">
    <div class="px-4 md:px-10 mx-auto w-full">
      <div>
      </div>
    </div>
  </div>
</template>

<script>
// import CardStats from "@/components/Cards/CardStats.vue";

export default {
  components: {
    // CardStats,
  },
};
</script>
