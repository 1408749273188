<template>
    <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
    >
      <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
          <h6 class="text-blueGray-700 text-xl font-bold">{{ titleHead }}</h6>
          <button
            @click.stop="save"
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button"
          >
            Spremi
          </button>
        </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form>
            <div class="flex">
              <input
                type="hidden"
                v-model="memberForAddEdit.id"
              />
				<img v-if="imageSrc" :src="imageSrc" class="rounded-md w-16 mr-2" alt="Selected Image" style="" />
                <div class="relative w-full mb-3">
					<input id="file-input" type="file" @change.stop="handleImageChange" class="form-file absolute top-0 left-0 opacity-0">
                    <label for="file-input" class="text-sm font-medium bg-gray-200 rounded-md p-2">
						Odaberi sliku
					</label>
              </div>
            </div>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Ime
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="memberForAddEdit.firstName"
                />
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Prezime
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="memberForAddEdit.lastName"
                />
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Email adresa
                </label>
                <input
                  type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="memberForAddEdit.email"
                />
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Kontakt telefon
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="memberForAddEdit.phone"
                />
              </div>
            </div>
          </div>

          <div class="flex flex-wrap">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Naziv pravobranilaštva
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="memberForAddEdit.nazivPravobranilastva"
                />
              </div>
            </div>
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  Pozicija u pravobranilaštvu
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  v-model="memberForAddEdit.pozicija"
                />
              </div>
            </div>
            <div v-if="forEdit" class="">
              <div class="relative">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                >
                  Aktivan član
                </label>
                <input
                  type="checkbox"
                  class=""
                  v-model="memberForAddEdit.isActive"

                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: 'NewEditMember',
  
    data() {
      return {
        memberForAddEdit: {},
        contentEn: false,
        imageSrc: '',
        fileUrl: '',
        forEdit: false,
      };
    },
  
    props: {
      member: {
        type: Object,
        required: false,
        default: (() => {}),
      },
    },
  
    created() {
      if (this.$route.params?.id !== 'new') {
        this.$store.dispatch('getMemberById', this.$route.params?.id).then(({ data }) => {
            Object.assign(this.memberForAddEdit, data);
            this.imageSrc = data.image;
        })
        this.forEdit = true;
      }
    },

    computed: {
        titleHead() {
            return this.forEdit ? this.memberForAddEdit.firstName + ' ' + this.memberForAddEdit.lastName : 'Dodaj novi član';
        },

        currentUser() {
            return this.$store.getters.currentUser;
        },
    },

    methods: {
      save() {
        let formData = new FormData();
        formData.append("FirstName", this.memberForAddEdit.firstName);
        formData.append("LastName", this.memberForAddEdit.lastName);
        formData.append("Email", this.memberForAddEdit.email);
        formData.append("Phone", this.memberForAddEdit.phone);
        formData.append("NazivPravobranilastva", this.memberForAddEdit.nazivPravobranilastva);
        formData.append("Pozicija", this.memberForAddEdit.pozicija);
        formData.append("Password", "password");
        formData.append("UserName", this.memberForAddEdit.email);



        formData.append("UserCreatedId", this.$store.getters.currentUser.id);

        if (this.memberForAddEdit.file) {
            formData.append("Image", this.memberForAddEdit.file);
        }


      this.$store.dispatch('setLoading', true);

      if (this.forEdit) {
          formData.append("IsActive", this.memberForAddEdit.isActive);
          if (this.memberForAddEdit.file) {
            formData.append("Image", this.memberForAddEdit.file);
          }
          this.$store.dispatch('updateMember', {
              id: this.memberForAddEdit.id,
              payload: formData,
          }).then(() => {
          this.$store.dispatch('setLoading', false);
          this.$toast.success('Uspješno ste izmijenili član.');
          }).catch(() => {
              this.$store.dispatch('setLoading', false);
              this.$toast.error('Došlo je do greške!');
          });
              return;
          }
          this.$store.dispatch('addMember', formData).then(() => {
          this.openMember(this.memberForAddEdit.id);
          this.$store.dispatch('setLoading', false);
          this.$toast.success('Uspješno ste dodali novog člana!');
              }).catch(() => {
          this.$store.dispatch('setLoading', false);
          this.$toast.error('Došlo je do greške!');
      })
    },
  
    openMember(id) {
      this.$store.dispatch('getMemberById', id).then(() => {
        this.$router.push({ name: 'member', params: { id } });
      });
    },
  
      handleImageChange(event) {
        var file = event.target.files[0];
              this.memberForAddEdit.file = file;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        }
      },
    },
  
    watch: {
      '$route.params.id': {
        immediate: true,
        handler (newVal) {
            if (newVal === 'new') {
              this.memberForAddEdit = {};
              this.imageSrc = '';
              this.forEdit = false;
            }
        }
      }
    },
  };
  
  </script>  