<template>
    <div class="flex flex-wrap mt-4" style="height: calc(100vh - 165px);">
      <div class="w-full mb-12 px-4">
        <members-table />
      </div>
    </div>
  </template>
  <script>
  import MembersTable from "@/components/Tables/MembersTable.vue";
  
  export default {
    name: 'Members',
    components: {
        MembersTable
    },
  };
  </script>
  