<template>
    <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
    >
      <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
          <h6 class="text-blueGray-700 text-xl font-bold">{{ titleHeader }}</h6>
          <button
            @click.stop="save"
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button"
          >
            Spremi
          </button>
        </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form>
          <div v-if="!hasOrganiVlasti">
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Detalji
            </h6>
            <img v-if="imageSrc && !isTagDocument" :src="imageSrc" class="object-cover w-full rounded-md" alt="Selected Image" style="height: 280px;" />
            <div class="flex flex-wrap">
              <div v-if="!isTagDocument" class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <input id="file-input" type="file" @change.stop="handleImageChange" class="form-file absolute top-0 left-0 opacity-0">
                  <label for="file-input" class="text-sm font-medium bg-gray-200 rounded-md p-2">
                    Odaberi sliku
                  </label>
                </div>
              </div>
              <div class="flex px-4">
                <div class="relative mb-3">
                  <input id="file-input" type="file" @change.stop="handleFileChange" class="form-file absolute top-0 left-0 opacity-0">
                  <label for="file-input" class="text-sm font-medium bg-gray-200 rounded-md p-2">
                    Odaberi dokument
                  </label>
                </div>
                <div class="mx-2">{{ fileUrl }}</div>
              </div>
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Naziv
                  </label>
                  <input
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    v-model="blogForAddEdit.title"
                  />
                </div>
              </div>
              <div class="w-full lg:w-12/12 px-4" v-if="forEdit">
                <div class="relative w-full mb-3">
                  <input id="visible" type="checkbox" v-model="blogForAddEdit.visible" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                  <label htmlFor="visible" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 uppercase text-blueGray-600 text-xs font-bold mb-2">Aktivan</label>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!isTagDocument" class="flex flex-wrap">
            <div class="w-full lg:w-12/12 px-4">
              <div class="relative w-full mb-3">
                <label for="description" class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Text</label>
                <editor v-if="!contentEn"
                    v-model="blogForAddEdit.content"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </template>
<script>

import Editor from '@/components/Widgets/Editor.vue';

export default {
  name: 'NewEditBlog',

  data() {
    return {
      blogForAddEdit: {},
      contentEn: false,
			imageSrc: '',
      fileUrl: '',
			forEdit: false,
    };
  },

  components: {
    Editor,
  },

  props: {
    blog: {
      type: Object,
      required: false,
      default: (() => {}),
    },
  },

  created() {
		if (this.$route.params?.id !== 'new') {
			this.$store.dispatch('getBlogById', this.$route.params?.id).then(({ data }) => {
				Object.assign(this.blogForAddEdit, data);
				this.imageSrc = data.imageUrl;
        this.fileUrl = data.fileUrl;
			})
			this.forEdit = true;
		}
  },

  computed: {
    tag() {
      return this.$route.params.tag || '';
    },

    isTagDocument() {
      return this.tag === 'dokumenti';
    },

    hasOrganiVlasti() {
      return this.blogForAddEdit?.tag === 'organi';
    },

    titleHeader() {
      switch (this.tag) {
        case 'dokumenti':
          return 'Novi dokument';
        case 'radovi-projekti':
          return 'Novi rad/projekat';
        case 'oglasna':
          return 'Novi obavijest';
        case 'organi':
          return 'Organi vlasti';
        default:
          return 'Novi';
      }
    },

    toastMessages() {
      const addEditLabel = this.forEdit ? 'izmijenili' : 'dodali';
      switch (this.tag) {
        case 'dokumenti':
          return {
            success: `Uspješno ste ${addEditLabel} dokument!`,
            error: 'Došlo je do greške prilikom dodavanja dokumenta!',
          };
        case 'radovi-projekti':
          return {
            success: `Uspješno ste ${addEditLabel} rad/projekat!`,
            error: 'Došlo je do greške prilikom dodavanja rada/projekta!',
          };
        case 'oglasna':
          return {
            success: `Uspješno ste ${addEditLabel} obavijest!`,
            error: 'Došlo je do greške prilikom dodavanja obavijesti!',
          };
        case 'organi':
          return {
            success: `Organi vlasti uspješno izmijenjeni!`,
            error: 'Došlo je do greške prilikom izmjene organa vlasti!',
          };
        default:
          return {
            success: `Uspješno ste ${addEditLabel} blog!`,
            error: 'Došlo je do greške prilikom dodavanja bloga!',
          };
      }
    },
  },

  methods: {
    save() {
			let formData = new FormData();
			formData.append("Title", this.blogForAddEdit.title);
			formData.append("Content", this.blogForAddEdit.content);
			formData.append("TitleEn", '<p></p>');
			formData.append("ContentEn", '<p></p>');

			formData.append("UserCreatedId", this.$store.getters.currentUser.id);

			if (this.blogForAddEdit.file) {
				formData.append("File", this.blogForAddEdit.file);
			}

      if (this.blogForAddEdit.document) {
				formData.append("Document", this.blogForAddEdit.document);
			}

      formData.append("Tag", this.$route.params.tag || '');

      this.$store.dispatch('setLoading', true);

      if (this.forEdit) {
				formData.append("Visible", this.blogForAddEdit.visible);
				this.$store.dispatch('updateBlog', {
					id: this.$route.params?.id,
					payload: formData,
				}).then(() => {
          this.$store.dispatch('setLoading', false);
          this.$toast.success(this.toastMessages.success);
        }).catch(() => {
          this.$store.dispatch('setLoading', false);
          this.$toast.error(this.toastMessages.error);
        });
				return;
			}
			this.$store.dispatch('createBlog', formData).then((data) => {
				this.openBlog(data.blog.blogId);
        this.$store.dispatch('setLoading', false);
        this.$toast.success(this.toastMessages.success);
			}).catch(() => {
        this.$store.dispatch('setLoading', false);
        this.$toast.error(this.toastMessages.error);
      })
    },

		openBlog(id) {
        this.$store.dispatch('getBlogById', id).then(() => {
          this.$router.push({ name: 'blog', params: { id, tag: this.tag } });
					this.forEdit = true;
        });
      },

		handleFileChange(event) {
      var file = event.target.files[0];
			this.blogForAddEdit.document = file;
      console.log(file);
      this.fileUrl = file ? file.name : '';
    },

    handleImageChange(event) {
      var file = event.target.files[0];
			this.blogForAddEdit.file = file;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.imageSrc = e.target.result;
      }
    },
  },

  watch: {
		'$route.params.id': {
			immediate: true,
			handler (newVal) {
				if (newVal === 'new') {
					this.blogForAddEdit = {};
					this.contentEn = false;
					this.imageSrc = '';
					this.forEdit = false;
				}
			}
		}
  },
};

</script>  