const mutations = {
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
    setBlogs(state, blogs) {
      state.blogs = blogs;
    },
    setCurrentBlog(state, blog) {
      state.currentBlog = blog;
    },
    setGalleryPhotos(state, payload) {
      state.galleryPhotos = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setMembers(state, payload) {
      state.members = payload;
    },
    setOrganiVlasti(state, payload) {
      state.organiVlasti = payload;
    }
};

export default mutations;