import axios from 'axios';
import authHeader from '../helpers/authHeader';

const API_URL = 'https://api.upufbih.ba/api/';
// const API_URL = 'http://localhost:5000/api/';

export default {
  login({ commit }, user) {
      return axios.post(API_URL + 'Auth/Login', {
        userName: user.username,
        password: user.password,
      }).then(response => {
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data));
          commit('setCurrentUser', response.data.user);
        }

        return response.data;
      });
  },

  logout({ commit }) {
    localStorage.removeItem('user');
    commit('setCurrentUser', {});
  },

  /* BLOG METHODS */

  // eslint-disable-next-line no-unused-vars
  createBlog({ commit }, payload) {
    return axios.post(`${API_URL}Blogs/Add`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...authHeader(),
        },
      })
      .then(response => {
        return response.data;
    });
  },

  getBlogs({ commit }, tag) {
    return new Promise((resolve,reject)=>{
        axios.get(`${API_URL}Blogs/GetAllBlogs?tag=${tag}`, { headers: authHeader() })
        .then((response)=>{
            commit('setBlogs', response.data);
            resolve(response)
        })
        .catch((error)=>{
            reject(error)
        })
    })
  },

  // eslint-disable-next-line no-unused-vars
  updateBlog({ commit }, { id, payload }) {
    return new Promise((resolve,reject)=>{
      axios.put(`${API_URL}Blogs/UpdateBlog/${id}`, payload, { headers: authHeader() })
      .then((response)=>{
          resolve(response)
      })
      .catch((error)=>{
          reject(error)
      })
  })
  },

  getBlogById({ commit }, id) {
    return new Promise((resolve,reject)=>{
      axios.get(`${API_URL}Blogs/GetBlogById?id=${id}`, { headers: authHeader() })
      .then((response)=>{
          commit('setCurrentBlog', response.data);
          resolve(response)
      })
      .catch((error)=>{
          reject(error)
      })
    })
  },

  // eslint-disable-next-line no-unused-vars
  deleteBlog({ commit }, id) {
    return new Promise((resolve,reject)=>{
      axios.delete(`${API_URL}Blogs/Delete/${id}`, { headers: authHeader() })
      .then((response)=>{
          resolve(response)
      })
      .catch((error)=>{
          reject(error)
      })
    })
  },

  /* GALLERY METHODS */

  // eslint-disable-next-line no-unused-vars
  uploadGalleryImage({ commit }, file) {
    const formData = new FormData();
    formData.append('File', file);
    return axios.post(`${API_URL}Gallery/AddPhoto`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...authHeader(),
        },
      })
      .then(response => {
        return response.data;
    });
  },


  getGallery({ commit }) {
    return new Promise((resolve,reject)=>{
        axios.get(API_URL + 'Gallery/GetAllPhotos', { headers: authHeader() })
        .then((response)=>{
            commit('setGalleryPhotos', response.data);
            resolve(response)
        })
        .catch((error)=>{
            reject(error)
        })
    })
  },

  // eslint-disable-next-line no-unused-vars
  activeGalleryPhoto({ commit }, id) {
    return axios.post(`${API_URL}Gallery/SetActivePhoto/${id}`, {queryData: "dataValue"}, { headers: authHeader() })
      .then(response => {
        return response.data;
    });
  },

  // eslint-disable-next-line no-unused-vars
  deleteGalleryPhoto({ commit }, id) {
    return axios.delete(`${API_URL}Gallery/DeletePhoto/${id}`, { headers: authHeader() })
      .then(response => {
        return response.data;
    });
  },

  setLoading({ commit }, value) {
    commit('setLoading', value);
  },

  /* MEMBERS METHODS */
  // eslint-disable-next-line no-unused-vars
  addMember({ commit }, payload) {
    return axios.post(`${API_URL}Users/AddUser`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...authHeader(),
        },
      })
      .then(response => {
        return response.data;
    });
  },

  getMembers({ commit }) {
    return new Promise((resolve,reject)=>{
        axios.get(`${API_URL}Users/GetUsers?UserId=1`, { headers: authHeader() })
        .then((response)=>{
            commit('setMembers', response.data);
            resolve(response)
        })
        .catch((error)=>{
            reject(error)
        })
    })
  },

  getMemberById({ commit }, id) {
    return new Promise((resolve,reject)=>{
        axios.get(`${API_URL}Users/GetUser/${id}`, { headers: authHeader() })
        .then((response)=>{
            commit('setCurrentUser', response.data);
            resolve(response)
        })
        .catch((error)=>{
            reject(error)
        })
    })
  },

  // eslint-disable-next-line no-unused-vars
  updateMember({ commit }, { id, payload }) {
    return new Promise((resolve,reject)=>{
      axios.put(`${API_URL}Users/AdminUpdateUser/${id}`, payload, { headers: authHeader() })
      .then((response)=>{
          resolve(response)
      })
      .catch((error)=>{
          reject(error)
      })
    })
  },

  getOrganiVlasti({ commit }) {
    // Organi vlasti text is stored in the database with the id of 21 or tag name "organi"
    const organiVlastiId = 21;
    return new Promise((resolve,reject)=>{
      axios.get(`${API_URL}Blogs/GetBlogById?id=${organiVlastiId}`, { headers: authHeader() })
      .then((response)=>{
          commit('setOrganiVlasti', response.data);
          resolve(response)
      })
      .catch((error)=>{
          reject(error)
      })
    })
  },
};