const getters = {
  currentUser:state=>state.currentUser,
  blogs: state => state.blogs.sort((a, b) => b.blogId - a.blogId) || [],
  currentBlog: state => state.currentBlog,
  gallery: state => state.galleryPhotos.sort((a, b) => b.galleryID - a.galleryID) || [],
  getLoading: state => state.loading,
  members: state => state.members.sort((a, b) => b.id - a.id),
  organiVlasti: state => state.organiVlasti,
};

export default getters;