const getDefaultState = {

    currentBlog: {},
    blogs: [],
    currentUser: {},
    galleryPhotos: [],
    loading: false,
    members: [],
    organiVlasti: {},
};

export default getDefaultState;